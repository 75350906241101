
/* ======================================== */
/* Variables */
/* ======================================== */

:root {

  --grayPrimary: #2D2D2D;
  /* --graySecondary: #6D6D6D; */
  --graySecondary: #696969;
  --grayTertiary: #DCDCDC;
  --grayLight: #eeeeee;
  --grayVeryLight: #f3f3f3;
  --grayVeryVeryLight: #FCFCFC;

  --whitePrimary: #ffffff;

  --accentPrimary: #25DFAB;
  --accentDark: #1D5848;

  --error: #810544;

  --highlightActive: #349df9;
  --highlight: #a7c4dd;

  --paddingPrimay: 30px;
  --paddingSecondary: 15px;
  --paddingTertiary: 10px;

}




/* ======================================== */
/* Clean */
/* ======================================== */

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--grayPrimary);
}

h1, h2, h3, h4, h5, p {
  padding: initial;
  margin: initial;
  margin-bottom: 15px;
}

a {
  color: var(--grayPrimary);
}

button {
  border: initial;
  background-color: initial;
  font-size: initial;
  cursor: pointer;
}

input,
textarea {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: var(--grayPrimary);
}

input {
  box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}




/* ======================================== */
/* Flex helpers */
/* ======================================== */

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}



/* ======================================== */
/* Basic layout */
/* ======================================== */

body {
  color: var(--grayPrimary);
}

.wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.left-column {
  z-index: 100;
  width: 260px;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  background-color: #FBFCFC;
}



.right-column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--whitePrimary);
}

.top-bar {
  border-bottom: 1px solid var(--grayVeryLight);
  background-color: var(--whitePrimary);
}



header {
  width: 100%;
  padding: 15px 30px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--grayTertiary);
  background-color: var(--whitePrimary);
}

/* section.sub-header-container {
  align-self: flex-start;
  width: 100%;
  border-bottom: 1px solid var(--grayTertiary);
  background-color: var(--whitePrimary);
} */

section.body-content {
  align-self: flex-start;
  flex-grow: 1;
  width: 100%;
  padding: var(--paddingPrimay);
  padding-bottom: 115px;
  overflow-y: scroll;
  box-sizing: border-box;
}

.left-bar {
  max-width: 800px;
}

p.error {
  color: var(--error);
}

a.sub-text,
p.sub-text {
  color: var(--graySecondary);
}

.error-messsage-conainer {
  min-height: 30px;
}


@media screen and (max-width: 500px) {

  section.body-content {
    padding: 15px;
  }

}




/* ======================================== */
/* Logos */
/* ======================================== */

.logo-square {
  width: 55px;
  height: 55px;
  background-image: url('./images/dia-logo-mark.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}


@media screen and (max-width: 750px) {

  .logo-square {
    width: 30px;
    height: 30px;
  }

}



/* ======================================== */
/* Buttons */
/* ======================================== */

button.button {
  padding: 10px 15px;
  color: var(--grayPrimary);
  border-radius: 5px;
  border: 1px solid var(--grayTertiary);
}

button.button:hover {
  border: 1px solid var(--graySecondary);
  box-shadow: 0 5px 10px -1px rgb(0 0 0 / 10%);
}

button.button:disabled {
  opacity: .4;
}

button.checkbox-button {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  margin-right: 5px;
  border-radius: 50px;
  border: 2px solid #7a7a7a;
  cursor: pointer;
}

button.checkbox-button:hover {
  /* background-color: var(--whitePrimary); */
  /* box-shadow: 0 5px 10px -1px rgb(0 0 0 / 10%); */
}

button.item-action-button {
  border-radius: 5px;
}

button.item-action-button:hover {
  box-shadow: 0 5px 10px -1px rgb(0 0 0 / 20%);
}

button.item-action-button:active {
  opacity: .25;
}



button.item-action-button-secondary {
  display: flex;
  flex-direction: row;
  padding: 3px 8px;
  border: 1px solid var(--grayTertiary);
  border-radius: 5px;
  font-size: .8rem;
  color: var(--graySecondary);
  opacity: .7;
}

button.item-action-button-secondary:hover {
  box-shadow: 0 5px 10px -1px rgb(0 0 0 / 10%);
  opacity: 1;
}

button.item-action-button-secondary svg {
  margin-top: 1px;
  padding-left: 5px;
}

button.item-action-button-secondary:hover svg {
  margin-top: 0px;
}

button.item-action-button-secondary:active {
  opacity: .25;
}



button.item-action-button-secondary.delete-button {
  border: none;
  width: 74px;
  justify-content: flex-end;
}

button.item-action-button-secondary.delete-button:hover {
  box-shadow: none;
}


.item-action-button-spacer {
  width: 24px;
  height: 22px;
  margin-right: 10px;
}

.item-action-button-indent-spacer {
  width: 54px;
  height: 22px;
  margin-right: 10px;
}

.push-spacer {
  width: 77px;
}


button.autosave-button {
  display: none;
}




/* ======================================== */
/* Inputs */
/* ======================================== */

form.form {
  max-width: 600px;
}

.input-label {
  margin-bottom: var(--paddingSecondary);
}

.input-label input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--grayTertiary);
  border-radius: 5px;
}

.select-basic {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--grayTertiary);
  border-radius: 5px;
}




/* ======================================== */
/* Search */
/* ======================================== */

.search-bar-container {
  flex-grow: 1;
  max-width: 300px;
  padding-left: 30px;
}

.serach-bar-inner {
  position: relative;
}

.input-search {
  width: 100%;
  padding: 8px;
  padding-right: 30px;
  border-radius: 50px;
  border: 1px solid var(--graySecondary);
}

.search-clear-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 34px;
  width: 34px;
  opacity: .7;
}

.search-clear-button:hover {
  opacity: 1;
}

.found-items-count-container {
  justify-content: flex-end;
  position: absolute;
  z-index: 300;
  /* top: 0px; */
  right: 15px;
  width: calc(100% - 60px);
  padding: 8px 15px;
  text-align: right;
  font-size: .7rem;
  font-style: italic;
  color: var(--graySecondary);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--grayTertiary);

  background-color: var(--whitePrimary);
}

.found-items-button-container {
  min-width: 56px;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid var(--grayTertiary);
}

.found-items-button-container.inactive {
  opacity: .25;
}

.found-items-count-container button {
  height: 20px;
}

.found-items-count-container button:hover {
  border-radius: 10px;
  background-color: var(--grayVeryLight);
}

.found-items-count-container button:active {
  opacity: .5;
}

.found-items-count-container button div {
  width: 16px;
  height: 16px;
}



/* ======================================== */
/* Left column */
/* ======================================== */

.left-column a {
  display: block;
  padding: 5px 0;
  margin: 10px 0;
  text-decoration: none;
  font-size: 1.35rem;
  color: var(--graySecondary);
}

.left-column a:hover {
  text-decoration: underline;
}

.left-column a.active {
  color: var(--grayPrimary);
  text-decoration: underline;
  text-decoration-color: #12D8BA;
}

.left-column button.help-button,
.left-column button.logout-button {
  padding: initial;
  color: var(--graySecondary);
}

.left-column button.help-button:hover,
.left-column button.logout-button:hover {
  text-decoration: underline;
}

.contact-support-container {
  margin-top: 60px;
  font-size: .9rem;
  line-height: 1.2rem;
}

.contact-support-container a {
  margin: initial;
  font-size: .9rem;
  line-height: 1.2rem;
  color: var(--graySecondary)
}


@media screen and (max-width: 900px) {

  .left-column {
    width: 200px;
  }

  .left-column a {
    font-size: 1.125rem;
  }

  .contact-support-container a {
    font-size: .8rem;
  }

}


@media screen and (max-width: 750px) {

  .left-column {
    display: none;
  }

}




/* ======================================== */
/* Right column */
/* ======================================== */








/* ======================================== */
/* Top bar */
/* ======================================== */

.top-bar-inner {
  justify-content: space-between;
  max-width: 800px;
  padding: 30px;
}


.top-bar-buttons-container button {
  font-size: .9rem;
  border-radius: 100px;
}

.top-bar-buttons-container button:first-of-type {
  margin-right: 15px;
}

.top-bar-buttons-container button svg {
  margin-right: 5px;
}

.top-bar-mobile {
  display: none;
}

.nav-more-menu {
  display: none;
}



@media screen and (max-width: 900px) {

  .top-bar {
    left: 190px;
    width: calc(100% - 190px);
  }

}

@media screen and (max-width: 750px) {

  .top-bar {
    left: 0px;
    width: 100%;
  }

  .top-bar-inner {
    padding: 15px;
    background-color: var(--grayVeryVeryLight);
  }

  .top-bar-mobile {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid var(--grayTertiary);
    background-color: var(--whitePrimary);
  }

  .nav-more-menu {
    display: block;
    /* padding: 15px 30px; */
  }

  .nav-more-menu:hover,
  .more-menu-container button:hover {
    opacity: .8;
  }

}

@media screen and (max-width: 500px) {

  .top-bar-buttons-container button {
    font-size: .8rem;
  }

  .top-bar-buttons-container button svg {
    display: none;
  }

  .top-bar-buttons-container button:first-of-type {
    margin-right: 5px;
  }

}


/* ======================================== */
/* Settings */
/* ======================================== */

.settings-field-container,
.settings-checkbox-container {
  margin: 15px 0;
}

.settings-field-container div {
  width: 120px;
}

.settings-field-container input {
  width: 220px;
  padding: 5px;
}

.settings-field-container input:disabled {
  border: initial;
  background-color: initial;
}


.settings-checkbox-container input {
  margin-right: 10px;
}

.settings-message {
  min-height: 35px;
  margin-top: 45px;
  color: var(--accentDark);
  font-style: italic;
}





/* ======================================== */
/* Header */
/* ======================================== */


header h1 {
  margin-bottom: initial;
  margin-left: 15px;
}




@media screen and (max-width: 985px) {

  header h1 {
    font-size: 1.75rem;
  }

}

@media screen and (max-width: 800px) {

  header h1 {
    font-size: 1.5rem;
  }

}

@media screen and (max-width: 500px) {

  header {
    padding: 15px;
  }

}




/* ======================================== */
/* More menu */
/* ======================================== */

.more-menu-container {
  position: fixed;
  align-items: flex-start;
  z-index: 200;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;

  background-color: rgba(255, 255, 255, 0.95);
}

.more-menu-links-container {
  flex-grow: 1;
  margin: 60px 0px;
}

.more-menu-links-container .flex-row.header-links {
  display: block;
}

.more-menu-links-container .flex-row.header-links a {
  display: block;
  text-align: left;
  padding: 15px 30px;
  margin: 15px 30px;
  font-size: 1.5rem;
}

.more-menu-links-container .header-links a {
  text-decoration: none;
  border-bottom: 3px solid var(--whitePrimary);;
}

.more-menu-links-container .header-links a.active {
  border-color: var(--accentPrimary);
}

.more-menu-container button {
  margin: 30px 30px 0 0;
}

.more-menu-container .contact-support-container {
  margin: 60px;
  font-size: 1rem;
}

.more-menu-links-container .header-links .contact-support-container a {
  padding: initial;
  margin: initial;
  margin-top: 5px;
  font-size: 1rem;
}


/* ======================================== */
/* Sub Header */
/* ======================================== */

section.sub-header-container {
  padding-top: var(--paddingSecondary);
  padding-bottom: var(--paddingSecondary);
  padding-left: var(--paddingPrimay);
  padding-right: var(--paddingPrimay);
  box-sizing: border-box;
}

.sub-header-button-container {
  margin-right: var(--paddingPrimay);
}

.sub-header-button-container:last-of-type {
  margin-right: initial;
}

.sub-header-button-container button {
  border: none;
  text-decoration: underline;
  color: var(--graySecondary);
}

.sub-header-button-container button:hover {
  border: none;
  box-shadow: none;
  color: var(---grayPrimary);
}


/* ======================================== */
/* Loading */
/* ======================================== */

.loading-basic-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px;
}




/* ======================================== */
/* Day Item Container */
/* ======================================== */

.day-item-container {
  padding: 9px 0;
  border-bottom: 1px solid var(--grayVeryLight);
}

/* .day-item-container.complete .checkbox-button, */
.day-item-container.complete textarea {
  /* opacity: .5; */
  color: var(--graySecondary);
}

.day-item-container.complete .checkbox-button {
  /* border: 2px solid var(--accentPrimary); */
  border-color: #00BB99;
}

.day-item-container:last-of-type {
  border-bottom: initial;
}

.day-item-container input {
  display: block;
  width: 100%;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid var(--grayVeryLight);
  color: var(--graySecondary);
}

/* .day-item-container:hover input {
  background-color: var(--grayVeryVeryLight);
} */

.day-item-container input:focus-visible {
  /* background-color: var(--grayVeryLight); */
  border: 1px solid var(--grayVeryLight);
  outline: none;
  color: var(--grayPrimary);
}


.day-item-container textarea {
  resize: none;
  display: block;
  width: 100%;
  /* min-height: 26px; */
  padding: 5px;
  margin-right: 5px;
  line-height: 1.2rem;
  border-radius: 5px;
  border: none;
  /* border: 1px solid var(--grayVeryLight); */
  /* border: 1px solid transparent; */
  /* border: 1px solid #F5F5F5; */
  /* color: var(--graySecondary); */
  border: 1px solid transparent;
  background-color: transparent;
}

.day-item-container textarea::-webkit-scrollbar {
  display: none;
}

.day-item-container.no-text .checkbox-button {
  border-color: #d1d1d1;
  cursor: initial;
}

.day-item-container.no-text textarea {
  border: 1px solid var(--grayLight);
}

.day-item-container textarea::placeholder {
  color: var(--graySecondary);
  font-style: italic;
  opacity: .75;
}

.day-item-container:hover textarea {
  /* background-color: var(--grayVeryVeryLight); */
  /* border: 1px solid var(--grayLight); */
  /* background-color: #FDFDFD; */
  background-color: #FCFCFC;
}

.day-item-container.complete textarea:focus-visible,
.day-item-container textarea:focus-visible {
  outline: none;
  color: var(--grayPrimary);
  background-color: #F9F9F9;
  /* border: 1px solid var(--grayTertiary); */
  /* color: var(--grayPrimary); */
  /* background-color: var(--whitePrimary); */
  /* box-shadow: 0 5px 10px -1px rgb(0 0 0 / 10%); */
}

.day-item-container textarea:hover {
  border-color: var(--grayTertiary);
}


/* .day-item-container .content-editable {
  display: block;
  resize: none;
  width: 100%;
  padding: 5px 10px;
  margin-right: 5px;
  line-height: 1.3rem;
  border-radius: 5px;
  border: 1px solid var(--grayVeryLight);
  color: var(--graySecondary);
} */

/* .day-item-container:hover .content-editable {
  background-color: var(--grayVeryVeryLight);
} */

/* .day-item-container .content-editable:focus-visible {
  border: 1px solid var(--grayVeryLight);
  outline: none;
  color: var(--grayPrimary);
} */

button.button.add-line-button {
  /* margin-top: 15px; */
  margin: 15px;
  padding: 7px 15px;
  color: var(--graySecondary);
  font-size: .7rem;
  border-radius: 50px;
  opacity: .75;
}

button.button.add-line-button:hover {
  border-color: var(--graySecondary);
  opacity: 1;
  background-color: var(--whitePrimary);
}


.day-item-container.highlight textarea {
  border-color: var(--highlight);
  box-shadow: 0 0px 10px 2px rgb(52 157 249 / 15%);
}

.day-item-container.highlight.highlight-active textarea {
  border-color: var(--highlightActive);
  box-shadow: 0 0px 10px 2px rgb(52 157 249 / 30%);
}

.day-item-container .day-item-extras-container {
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;
  margin-top: 3px;
  margin-left: 28px;
  color: var(--graySecondary);
  font-size: .7rem;
  font-style: italic;
}

.item-label {
  padding: 3px 10px;
  margin-left: 10px;
  border-radius: 3px;
  border: 1px solid var(--grayTertiary)
}

.item-label:first-of-type {
  margin-left: initial;
}

.item-label:last-of-type {
  margin-right: 38px;
}

.item-label-dot {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-top: -4px;
  margin-right: 5px;
  border-radius: 5px;
  background-color: var(--graySecondary);
}

.item-label-name {
  display: inline-block;
}

.item-label-name::first-letter {
  text-transform: capitalize;
}

.css-v68sna-control {
  min-height: initial !important;
  font-size: .9rem;
  border: 1px solid var(--grayTertiary) !important;
  border-radius: 5px !important;
}

.css-v68sna-control div::first-letter {
  text-transform: capitalize;
}

.css-1xc3v61-indicatorContainer {
  padding-top: initial !important;
  padding-bottom: initial !important;
}

.due-date,
.created-at-date {
  width: 140px;
}

.item-link-continer {
  max-width: 200px;
}


/* ======================================== */
/* Day */
/* ======================================== */

.day-container {
  margin-bottom: 30px;
  padding: 30px;
  border: 1px solid #DCE9E9;
  border-radius: 5px;
  background-color: var(--whitePrimary);

  /* background-color: var(--whitePrimary); */
  /* background-color: #FBFCFC; */

}

#today .day-container {
  /* border: 1px solid var(--accentPrimary);
  box-shadow: 0 5px 10px -1px rgb(0 0 0 / 20%); */
}

.day-container h3 {
  margin: 0px 0 15px 0;
  font-size: 1.2rem;
  font-weight: 400;
  /* color: var(--graySecondary); */
}

.dot {
  font-size: .5rem;
}


.day-header,
.day-tasks,
.day-blockers {
  /* padding: 15px; */
}

.day-header {
  position: relative;
  /* padding-bottom: 0px; */
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #F4F7F7; */
}

.day-header h2 {
  margin-bottom: 5px;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.4rem;
}

.day-header h3 {
  margin-bottom: initial;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  /* color: var(--grayPrimary); */
}

.day-header p {
  font-size: 1.1rem;
  font-weight: 200;
}

.day-header .inner {
  position: relative;
  z-index: 2;
}

.day-header .color-block {
  position: absolute;
  z-index: 1;
  bottom: -5px;
  left: -5px;
  width: 70px;
  height: 20px;
  background-color: #1BFED5;
  opacity: .4;
}

#today .day-header .color-block {
  bottom: 20px;
  opacity: 1;
}

.day-tasks,
.day-blockers {
  /* margin-top: 30px; */
  /* border-top: 1px solid #E3EAEA; */
}

.day-tasks {
  /* background-color: #FBFCFC; */
  margin-top: 45px;
}

.day-blockers {
  /* border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #F7F9F9; */
  margin-top: 45px;
}

.day-blockers .day-item-container {
  padding: 0px;
  padding-left: 0px !important;
}

.day-blockers .day-item-container textarea {
  /* background-color: #FBFCFC;
  border: 1px solid #E3EAEA; */
}

.day-blockers .day-item-container textarea:hover {
  /* background-color: var(--whitePrimary); */
  /* border: 1px solid var(--grayTertiary); */
}

.day-blockers button.button.add-line-button {
  margin-left: 0px;
}




/* ======================================== */
/* Modal */
/* ======================================== */

.modal-wrapper {
  position: fixed;
  z-index: 200;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal-container {
  width: 80%;
  height: 80%;
  max-width: 700px;
  max-height: 800px;
  padding: 30px;
  overflow: scroll;
  border: 1px solid var(--grayTertiary);
  border-radius: 5px;
  box-shadow: 0 5px 10px -1px rgb(0 0 0 / 40%);
  background-color: var(--whitePrimary);
}





/* ======================================== */
/* Modal */
/* ======================================== */

.help-modal-inner {
  flex-grow: 1;
}

.help-modal-inner div {
  margin-bottom: 30px;
}

.help-modal-inner .help-command {
  flex-shrink: 0;
  width: 130px;
  color: var(--accentDark);
  font-style: italic;
}

/* .help-modal-inner .help-description {

} */






/* ======================================== */
/* Standup */
/* ======================================== */

.standup-text-container {
  overflow-wrap: anywhere;
}





/* ======================================== */
/* To Do Soon */
/* ======================================== */

.body-content.to-do-soon .item-action-button-secondary.push-button {
  display: none;
}

.body-content.to-do-soon .day-item-container {
  border-radius: 10px;
}



/* ======================================== */
/* History */
/* ======================================== */

.history-wrapper {
  width: 100%;
  border-top: 1px solid var(--grayTertiary);
}

.history-container {
  padding: 15px;
}

.history-container .button {
  margin: 5px;
}

.history-container .button.active {
  color: var(--whitePrimary);
  border-color: var(--accentDark);
  background-color: var(--accentPrimary);
}

.history-week-container {
  margin-bottom: 90px;
}


.body-content.history button.checkbox-button:hover {
  cursor: initial;
  background-color: initial;
  box-shadow: initial;
}

.body-content.history .day-item-container textarea:hover {
  border-color: transparent;
}

.body-content.history .day-item-container textarea:focus-visible {
  border-color: transparent;
  background-color: initial;
  box-shadow: initial;
}

.body-content.history .day-item-container:hover textarea {
  border-color: transparent;
  background-color: initial;
}

.body-content.history .edit-button-container {
  display: none;
}



/* ======================================== */
/* Edit */
/* ======================================== */

.edit-button-container {
  position: relative;
}

.edit-button {
  opacity: .6;
}

.edit-button:hover {
  opacity: 1;
}

.edit-button-menu {
  position: absolute;
  z-index: 100;
  right: 0px;
  width: 150px;
  border: 1px solid var(--grayTertiary);
  border-radius: 10px;
  background-color: var(--whitePrimary);
  box-shadow: 0 15px 20px -1px rgb(0 0 0 / 15%);
}

.edit-button-menu button {
  width: 100%;
  padding: 8px 12px;
  text-align: left;
  color: var(--graySecondary);
  font-size: .9rem;
}

/* .edit-button-menu button:first-of-type {
  margin-top: 5px;
} */

.edit-button-menu button.push-to-next-day {
  margin-bottom: 5px;
  padding-top: 10px;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  border-bottom: 1px solid var(--grayTertiary);
  /* background-color: var(--grayVeryVeryLight); */
}

.edit-button-menu .delete-button-container {
  justify-content: space-between;
  margin-top: 5px;
  border-top: 1px solid var(--grayTertiary);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #FFE9E9;
}

.edit-button-menu button.delete-button {
  width: auto;
}

.edit-button-menu button.confirm-button {
  width: auto;
  margin-top: 5px;
  font-style: italic;
  color: var(--error);
}

.edit-button-menu button:hover {
  color: var(---grayPrimary);
}



.edit-modal-wrapper {
  z-index: 400;
}

.edit-row {
  margin-top: 30px;
}

.edit-row-item {
  margin-right: 30px;
}

.edit-row-item label {
  display: block;
  margin-bottom: 5px;
}

.edit-row-item input {
  border: 1px solid var(--grayTertiary);
}

.edit-row-item input:focus-visible {
  border: 1px solid var(--grayTertiary);
}



/* ======================================== */
/* Landing Screen */
/* ======================================== */

.logo {
  width: 175px;
  height: 55px;
  margin-bottom: 45px;
  background-image: url('./images/dia-logo.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}

section.hero {
  position: relative;
  justify-content: center;
  height: 50vh;
  min-height: 450px;
  background-image: url('./images/dia-header.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

nav.landing-main-nav {
  position: absolute;
  z-index: 100;
  top: 0px;
  left: 0px;
  width: 100%;
}

nav.landing-main-nav .container-links {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 30px 0;
}

nav.landing-main-nav a {
  color: var(--whitePrimary);
  text-decoration: none;
}

nav.landing-main-nav a:hover {
  text-decoration: underline;
}

nav.landing-main-nav span {
  color: var(--whitePrimary);
  font-weight: 900;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid var(--whitePrimary);
}

.container-primary {
  width: 80vw;
  max-width: 1000px;
}

.container-hero {
  color: var(--whitePrimary);
}

.container-hero h1 {
  margin-bottom: 45px;
  font-size: 4rem;
  font-weight: 400;
}

.container-hero a {
  padding: 10px 30px;
  font-size: 1.25rem;
  color: var(--accentDark);
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  background-color: var(--whitePrimary);
}

.container-hero a:hover {
  box-shadow: 0 15px 20px -1px rgb(0 0 0 / 15%);
}


.section-landing-primary {
  justify-content: center;
  min-height: 150px;
  padding: 90px 0;
  border-bottom: 1px solid var(--grayTertiary);
}

.section-landing-primary:last-of-type {
  border-top: 1px solid var(--grayTertiary);
  border-bottom: initial;
}

.section-landing-secondary {
  justify-content: center;
  padding: 60px 0;
}


.section-landing-primary .sub-heading {
  font-size: 2rem;
  font-weight: 300;
}

.section-landing-primary h2 {
  font-size: 3rem;
  font-weight: normal;
}

.today-image {
  width: 650px;
  height: 320px;
  margin-left: 30px;
  background-image: url('./images/dia-today.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.week-image {
  width: 591px;
  height: 400px;
  margin-right: 30px;
  background-image: url('./images/dia-week.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.big-action-button {
  padding: 15px 45px;
  font-size: 1.5rem;
  color: var(--whitePrimary);
  text-decoration: none;
  border-radius: 5px;
  /* background-color: #0DBCB6; */
  background-color: #00a09b;
}

.big-action-button:hover {
  box-shadow: 0 15px 20px -1px rgb(0 0 0 / 15%);
}

.section-landing-secondary a.secondary-link {
  /* color: #0DBCB6; */
  color: #00a09b;
  text-decoration: none;
}

.section-landing-secondary a.secondary-link:hover {
  text-decoration: underline;
}


@media screen and (max-width: 850px) {

  .wrapper-landing-screen .container-primary .flex-row {
    flex-wrap: wrap;
  }

  .today-image {
    width: 450px;
    height: 230px;
  }

  .week-image {
    width: 309px;
    margin-bottom: 30px;
  }

}


@media screen and (max-width: 550px) {

  .wrapper-landing-screen .container-primary .flex-row {
    flex-direction: column;
    text-align: center;
  }

  /* .landing-main-nav {
    flex-direction: column;

    background-color: #1D5848;
  } */

  .container-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .container-hero .logo {
    margin-bottom: 30px;
    background-position: center;
  }

  .container-hero h1 {
    font-size: 3rem;
    text-align: center;
  }

  .container-hero a {
    font-size: 1.5rem;
  }

  .section-landing-primary {
    padding: 45px 0;
  }

  .today-image {
    width: 100%;
    background-position: top;
    margin-left: initial;
  }

  .week-image {
    width: 100%;
    margin-right: initial;
  }


  .section-landing-primary .sub-heading {
    font-size: 1.25rem;
  }

  .section-landing-primary h2 {
    font-size: 2rem;
  }

}

@media screen and (max-width: 450px) {

  .logo {
    width: 150px;
    height: 45px;
  }

  .container-hero h1 {
    font-size: 2.25rem;
  }

  .today-image {
    height: 165px;
  }

}




/* ======================================== */
/* Password Reset */
/* ======================================== */

.active-element-container {
  min-height: 200px;
}




/* ======================================== */
/* Sortable */
/* ======================================== */

.drag-handle {
  margin-right: 10px;
  cursor: grab;
  opacity: .5;
}

.sortable-ghost {
  opacity: .5;
  background-color: var(--grayTertiary);
}

.sortable-drag {
  background-color: var(--whitePrimary);
}

.sortable-drag .drag-handle {
  opacity: 1;
}